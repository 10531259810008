<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        If
        <stemble-latex content="$\Delta\text{H}^\circ_{\text{rxn}}=$" />
        <number-value :value="enthalpy" unit="\text{kJ/mol}" />
        and
        <stemble-latex content="$\Delta\text{S}^\circ_{\text{rxn}}=$" />
        <number-value :value="entropy" unit="\text{J K}^{-1}\text{mol}^{-1}" />
        for a hypothetical reaction, answer each of the questions shown below.
      </p>

      <p>a) The reaction changes spontaneity at a temperature of:</p>

      <calculation-input
        v-model="inputs.Tspont"
        class="mb-4"
        dense
        prepend-text="$\text{T:}$"
        append-text="$\text{K}$"
        :disabled="!allowEditing"
      />

      <p class="mb-0">
        b) The reaction is spontaneous at all temperatures
        <v-select
          v-model="inputs.aboveBelow"
          class="d-sm-inline-block ml-3 mr-3"
          style="width: 120px"
          dense
          :items="aboveBelowItems"
          placeholder="Select"
        />
        the answer in part a).
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'Question288',
  components: {
    CalculationInput,
    StembleLatex,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Tspont: null,
        aboveBelow: null,
      },
      aboveBelowItems: ['above', 'below'],
    };
  },
  computed: {
    entropy() {
      return this.taskState.getValueBySymbol('entropy').content;
    },
    enthalpy() {
      return this.taskState.getValueBySymbol('enthalpy').content;
    },
  },
};
</script>
